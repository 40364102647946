import { useState, useEffect } from "react";
import { Editor as MarkDownEditor } from "@bytemd/react";
import breaks from "@bytemd/plugin-breaks";
import gfm from "@bytemd/plugin-gfm";

// CSS
import "bytemd/dist/index.css";
import "./css/Editor.scss";

const microcmsAdminUrl = document.referrer;
const plugins = [breaks(), gfm()];

export const Editor = () => {
  const [state, setState] = useState({
    iframeId: "",
    defaultMessage: {},
  });

  const [value, setValue] = useState("");

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (
        e.isTrusted === true &&
        e.data.action === "MICROCMS_GET_DEFAULT_DATA"
      ) {
        setState({
          iframeId: e.data.id,
          defaultMessage: e.data.message,
        });

        window.parent.postMessage(
          {
            id: e.data.id,
            action: "MICROCMS_UPDATE_STYLE",
            message: {
              height: 420,
            },
          },
          microcmsAdminUrl
        );

        setValue(e.data.message.data);
      }
    });
  }, []);

  const postDataToMicroCMS = (editorContent) => {
    window.parent.postMessage(
      {
        id: state.iframeId,
        action: "MICROCMS_POST_DATA",
        message: {
          data: editorContent,
        },
      },
      microcmsAdminUrl
    );
  };

  useEffect(() => {
    postDataToMicroCMS(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <MarkDownEditor
      value={value}
      onChange={(v) => {
        setValue(v);
      }}
      mode={"split"}
      plugins={plugins}
    />
  );
};
